import { Button, Typography } from "@mui/material"
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import * as React from "react"
import hero from "../images/hero.jpeg"

export default function Hero() {
    return (
        <Paper
            sx={{
                position: 'relative',
                backgroundColor: 'grey.800',
                color: '#fff',
                mb: 4,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: {hero},
                margin: "auto"
            }}
            >
            {<img style={{ 
                    display: 'block', 
                    maxHeight: "100%", 
                    maxWidth: "100%",
                    margin: "auto"
                    }} 
                    src={hero} alt="hero" />
                }
            <Box
                sx={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        marginTop: "15%",
                        marginLeft: "30%"
                      }}
                >
                    <Typography sx = {{
                        fontSize: "3vw",
                        fontFamily: "monospace",
                        fontWeight: "700",
                        color: "#2E3B55",
                        align: "center",
                        display: "table",
                        margin: "0 auto 0 auto"
                    }}
                    >
                        MOULIQE
                    </Typography>
                    <Typography sx = {{
                        fontSize: "1.8vw",
                        fontFamily: "monospace",
                        fontWeight: "700",
                        color: "#2E3B55",
                        display: "table",
                        margin: "0 auto 0 auto"
                    }}
                    >
                        adjective / [mɔ-lɪk] - FUNDAMENTAL
                    </Typography>
                    <Typography sx = {{
                        fontSize: "1.2vw",
                        display: "table",
                        color: "#2E3B55",
                        margin: "10px auto 0 auto",
                        textAlign: "center",
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                    }}>
                        Enterprise Application & Dashboard Development<br/>
                        with Highest Quality & Service
                    </Typography>
                    <Button sx={{
                        color: "#2E3B55",
                        ':hover': {color: "white", backgroundColor: "#2E3B55"}, 
                        backgroundColor: "none",
                        border: 1,
                        borderColor: "#2E3B55",
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        display: "table",
                        fontSize: "1.2vw",
                        margin: "15px auto 0 auto",
                        p: "0.5vw 0.8vw 0.5vw 0.8vw"
                        }}>
                            Learn More
                        </Button>
                </Box>
            </Box>
        </Paper>
    )
}