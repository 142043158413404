import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { Container } from '@mui/system';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'

const pages = ['Services', 'About', 'Contact'];

export default function Navbar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null)

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    }

    return (
        <AppBar position = "fixed" style={{ background: '#2E3B55' }}>
            <Container maxWidth = 'xl'>
                <Toolbar disableGutters> 
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                          mr: 2,
                          display: { xs: 'flex', md : 'flex'},
                          flexGrow: 2,
                          fontFamily: 'monospace',
                          fontWeight: 700,
                          color: 'inherit',
                          textDecoration: 'none',
                        }}
                    >
                        MOULIQE
                    </Typography>
                    <Box sx = {{ flexgrow: 1, display: { xs : 'flex', md: 'none'}}}>
                        <IconButton
                            size = "large"
                            aria-haspopup = "true"
                            onClick = {handleOpenNavMenu}
                            aria-controls="menu-appbar"
                            color = "inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id = "menu-appbar"
                            anchorEl = {anchorElNav}
                            anchorOrigin = {{
                                vertical: 'bottom',
                                horizontal: 'left'
                            }}
                            keepMounted
                            transformOrigin ={{
                                vertical: 'top',
                                horizontal: 'left'
                            }}
                            open = {Boolean(anchorElNav)}
                            onClose = {handleCloseNavMenu}
                            sx = {{
                                display: {xs: 'block', md: 'none'}
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key = {page} onClick = {handleCloseNavMenu}>
                                    <Typography fontFamily = "Montserrat" textAlign = "center">{page}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    
                    <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                        <Button
                            key={page}
                            onClick={handleCloseNavMenu}
                            sx={{ my: 1, color: 'white', display: 'block' }}
                        >
                            {page}
                        </Button>
                        ))}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}