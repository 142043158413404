import { Typography } from "@mui/material";
import React from "react";
import "../contact.css"
import Button from "@mui/material/Button"

export default function Contact() {
    return (
        <div className = "container">
            <div className = "form">
                <div className = "contact-info">
                    <Typography sx = {{fontFamily: "Montserrat", color: "#2E3B55", fontWeight: "800"}}>Mouliqe Inc.</Typography>
                    <div className = "info">
                        <div className = "information">
                            <Typography sx = {{fontFamily: "Montserrat", color: "#2E3B55", fontWeight: "500"}}>1740 Lafayette Blvd, Norfolk, VA 23509</Typography>
                        </div>
                        <div className = "information">
                            <Typography sx = {{fontFamily: "Montserrat" , color: "#2E3B55", fontWeight: "500"}}><strong>Email:</strong> <a href = "mailto: relationships@mouliqe.com">relationships@mouliqe.com</a></Typography>
                        </div>
                        <div className = "information">
                            <Typography sx = {{fontFamily: "Montserrat", color: "#2E3B55", fontWeight: "500"}}><strong>Phone:</strong> +1 347 429 2441</Typography>
                        </div>
                    </div>
                </div>
                <div class = "contact-form">
                    <form>
                        <Typography sx = {{fontFamily: "Montserrat", color: "white"}}>Contact Us</Typography>
                        <div className = "input-container">
                            <input type = "text" name = "name" className = "input"/>
                            <label for = "">Full Name</label>
                        </div>
                        <div className = "input-container">
                            <input type = "email" name = "email" className = "input"/>
                            <label for = "">Email</label>
                        </div>
                        <div className = "input-container">
                            <input type = "phone" name = "phone" className = "input"/>
                            <label for = "">Phone</label>
                        </div>
                        <div className = "input-container textarea">
                            <textarea name = "message" className = "input"></textarea>
                            <label for = "">Message</label>
                        </div>
                        <div>
                            <Button sx={{
                                color: "#2E3B55",
                                ':hover': {color: "white", backgroundColor: "#2E3B55", border: 1}, 
                                backgroundColor: "white",
                                border: 1,
                                borderColor: "#2E3B55",
                                fontFamily: "Montserrat",
                                fontWeight: "400",
                                margin: "15px auto 0 auto",
                                p: "0.5vw 0.8vw 0.5vw 0.8vw"
                                }}
                            >
                            Submit</Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}