import * as React from 'react'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

export default function About() {
    return (
        <Container maxWidth = "md">
            <Typography
                align = "center"
                color = "#2E3B55"
                fontFamily = "Montserrat"
                fontSize = "3vw"
                fontWeight = "700"
                marginTop = "5%"
            >
                ABOUT <span style={{fontFamily: "monospace", verticalAlign: "top", backgroundColor: "#2E3B55", color: "white", paddingLeft: "2%", paddingRight: "2%", display: "inline-block"}}>MOULIQE</span>
            </Typography>
            <Typography
                align = "center"
                color = "#2E3B55"
                fontFamily = "Montserrat"
                fontSize = "calc(9px + 0.5vw)"
                fontWeight = "400"
                marginTop = "5%"
            >
                Mouliqe Connecs group of consulting expatriates with right projects and scopes. We bring world leading technology and services to our country at an affordable budget. Mouliqe uses small teams with relevant subject matter expertise dedicated to individual projects and operations. We guarantee every project to be executed 100% to scope and within agreed upon deadline.
            </Typography>
            <Stack sx = {{
                pt: "5%", pb: "5%" }}
                direction = "row"
                spacing = {2}
                justifyContent = "center"
            >
                <Button
                    sx = {{
                        fontFamily: "Montserrat",
                        color: "white",
                        backgroundColor: "#2E3B55",
                        ':hover': {color: "#2E3B55", backgroundColor: "white", border: 1}, 
                        fontSize: "calc(8px + 0.8vw)",
                        pr: "2%",
                        pl: "2%"
                    }}
                >Learn More</Button>
                <Button
                    sx = {{
                        fontFamily: "Montserrat",
                        color: "#2E3B55",
                        border: 1,
                        backgroundColor: "white",
                        ':hover': {color: "white", backgroundColor: "#2E3B55"},
                        fontSize: "calc(8px + 0.8vw)",
                        pr: "2%",
                        pl: "2%"
                    }}
                >Get in Touch </Button>
            </Stack>
        </Container>
    )
}