import * as React from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Services from './components/Services';
import About from './components/About';
import "./App.css"
import "./contact.css"
import Contact from "./components/Contact"

export default function MyApp() {
  return (
    <div>
      <Navbar />
      <main>
        <Hero />
        <Services id = "services" />
        <About id = "about"/>
        <Contact id = "contact"/>
      </main>
    </div>
  );
}
