import * as React from "react"
import Typography from '@mui/material/Typography';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BarChartIcon from '@mui/icons-material/BarChart';
import Box from '@mui/material/Box';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import Button from '@mui/material/Button'

export default function Services() {
    return (
        <Box id = "services" sx = {{width: "100%", display: "flex", justifyContent: "space-evenly", backgroundColor: "#2E3B55", paddingBottom: "1%"}}>
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={4} p = "10px">
                <Box gridColumn="span 4" textAlign="center">
                    <DisplaySettingsIcon
                        sx = {{
                            minWidth: "3vw",
                            minHeight: "3vw",
                            color: "white",
                            marginTop: "10px"
                        }}
                    />
                    <Typography
                        sx = {{
                            marginTop: "10px",
                            fontFamily: "Montserrat",
                            fontSize: "calc(8px + 0.5vw)",
                            color: "white",
                            fontWeight: "800",
                        }}
                    >
                        Enterprise Applications Development
                    </Typography>
                    <Typography
                        sx = {{
                            fontFamily: "Montserrat",
                            fontSize: "calc(6px + 0.5vw)",
                            marginTop: "8px",
                            mx: "auto",
                            maxWidth: "600px",
                            textAlign: "center",
                            pl: "7%",
                            color: "white"
                        }}
                    >
                        Large scale enterprise systems with full front and backend support 
                    </Typography>
                    <Button sx = {{
                        marginTop: "5%",
                        marginBottom: "5%",
                        color: "white",
                        border: 1,
                        borderColor: "white",
                        ':hover': {color: "#2E3B55", backgroundColor: "white"},
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        display: "table",
                        fontSize: "calc(6px + 0.3vw)",
                        margin: "15px auto 0 auto",
                        p: "2% 5% 2% 5%" 
                    }}
                    >
                        Learn More
                    </Button>
                </Box>
                <Box gridColumn="span 4" textAlign="center">
                    <DashboardIcon
                        sx = {{
                            minWidth: "3vw",
                            minHeight: "3vw",
                            color: "white",
                            marginTop: "10px"
                        }}
                    />
                    <Typography
                        sx = {{
                            marginTop: "10px",
                            fontFamily: "Montserrat",
                            fontSize: "calc(8px + 0.5vw)",
                            color: "white",
                            fontWeight: "800",
                        }}>
                        Dashboard and Analytics
                    </Typography>
                    <Typography
                        sx = {{
                            fontFamily: "Montserrat",
                            fontSize: "calc(6px + 0.5vw)",
                            marginTop: "10px",
                            maxWidth: "600px",
                            textAlign: "center",
                            px: "5%",
                            color: "white"
                        }}
                    >
                        Develop dashboards for all business cases and deeper dive into methodology
                    </Typography>
                    <Button sx = {{
                        marginTop: "5%",
                        marginBottom: "5%",
                        color: "white",
                        border: 1,
                        borderColor: "white",
                        ':hover': {color: "#2E3B55", backgroundColor: "white"},
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        display: "table",
                        fontSize: "calc(6px + 0.3vw)",
                        margin: "15px auto 0 auto",
                        p: "2% 5% 2% 5%" 
                    }}
                    >
                        Learn More
                    </Button>
                </Box>
                <Box gridColumn="span 4" textAlign="center">
                    <BarChartIcon
                        sx = {{
                            minWidth: "3vw",
                            minHeight: "3vw",
                            color: "white",
                            marginTop: "10px"
                        }}
                    />
                    <Typography
                        sx = {{
                            marginTop: "10px",
                            fontFamily: "Montserrat",
                            fontSize: "calc(8px + 0.5vw)",
                            color: "white",
                            fontWeight: "800",
                        }}
                    >
                        Data Analysis & Visualization
                    </Typography>
                    <Typography
                        sx = {{
                            fontFamily: "Montserrat",
                            fontSize: "calc(6px + 0.5vw)",
                            marginTop: "10px",
                            mx: "auto",
                            maxWidth: "600px",
                            textAlign: "center",
                            pr: "7%",
                            color: "white"
                        }}
                    >
                        Full support for all reporting tasks along with dashboard creation and integration
                    </Typography>
                    <Button sx = {{
                        marginTop: "5%",
                        marginBottom: "5%",
                        color: "white",
                        border: 1,
                        borderColor: "white",
                        ':hover': {color: "#2E3B55", backgroundColor: "white"},
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        display: "table",
                        fontSize: "calc(6px + 0.3vw)",
                        margin: "15px auto 0 auto",
                        p: "2% 5% 2% 5%" 
                    }}
                    >
                        Learn More
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}